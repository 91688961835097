import axios from 'axios';
import isTokenExpired from 'src/services/auth_header';
import { delete_cookie } from 'sfcookies';

// Define the API base URL
const apiBaseUrl =
  process.env.NODE_ENV === 'production' 
    ? 
    "https://lndprodbackend.iec.org.pk"
    : 
    "http://localhost:5000"; 

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: apiBaseUrl,
});

// Function to clear user session and redirect to login
const handleSessionExpiry = () => {
  if (!sessionStorage.getItem('redirected')) {
    sessionStorage.setItem('redirected', 'true'); // Mark as redirected
    sessionStorage.removeItem('token');
    document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    delete_cookie("auth");
    delete_cookie("token");
    window.location.href = "/"; // Redirect to login page
  }
};

if (typeof window !== 'undefined') {
  const getInfoLocal = JSON.parse(localStorage.getItem('userDetails'));

  if (getInfoLocal?.accessToken && !isTokenExpired()) {
    api.defaults.headers.common['Authorization'] = getInfoLocal?.accessToken;
    api.defaults.headers.common['Content-Type'] = "application/json";
  } else {
    // console.log('here')
    // handleSessionExpiry();
  }
}

// Add Axios response interceptor to handle expired tokens
api.interceptors.response.use(
  response => response, // Pass through successful responses
  error => {
    if (error.response?.status === 401) { // Check for unauthorized error
      handleSessionExpiry();
    }
    return Promise.reject(error); // Pass the error back to the calling code
  }
);

export default api;
